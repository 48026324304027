import { createStore as reduxCreateStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';

import Reducers from './reducers';
import { loadState, syncStateToStorage } from 'lib/storage';

// via https://github.com/gatsbyjs/gatsby/tree/master/examples/using-redux

const middlewares = [thunk];

if (process.env.NODE_ENV === 'development') {
  const logger = createLogger({
    collapsed: true
  });

  middlewares.push(logger);
}

const createStore = () => {
  const persistedState = loadState();
  const store = reduxCreateStore(
    Reducers,
    persistedState,
    applyMiddleware(...middlewares)
  );
  store.subscribe(() => syncStateToStorage(store));
  return store;
};
export default createStore;
