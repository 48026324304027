import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const ReactAlertTemplate = ({ style, options, message, close }) => (
  <div className="react-alert-template">
    <div className="react-alert-template-content">
      <div className="react-alert-template-icon">
        <FontAwesomeIcon icon={faExclamationTriangle} />
      </div>
      <div className="react-alert-template-message">{message}</div>
    </div>
  </div>
);

ReactAlertTemplate.propTypes = {
  style: PropTypes.any,
  options: PropTypes.object,
  message: PropTypes.any,
  close: PropTypes.func
};

export default ReactAlertTemplate;
