import { device } from 'fogg/lib';

const { isDomAvailable } = device;

const STATE_KEY = 'HEADLAMP';
let storage;

if (isDomAvailable() && typeof localStorage !== 'undefined') {
  storage = localStorage;
}

export function loadState () {
  const defaultState = {};

  if (!storage) return defaultState;

  let state, serializedState;

  try {
    serializedState = storage.getItem(STATE_KEY);
  } catch (error) {
    throw new Error(`Issue loading state from storage: ${error}`);
  }

  if (serializedState !== null) {
    state = JSON.parse(serializedState);
  }

  return state;
}

export function saveState (state) {
  try {
    const serializedState = JSON.stringify(state);
    storage.setItem(STATE_KEY, serializedState);
  } catch (error) {
    throw new Error(`Issue saving state to storage: ${error}`);
  }
}

export function clearState () {
  try {
    const serializedState = JSON.stringify({});
    storage.setItem(STATE_KEY, serializedState);
  } catch (error) {
    throw new Error(`Issue clearing state from storage: ${error}`);
  }
}

export function syncStateToStorage (store) {
  const whitelistedStateKeys = [
    {
      key: 'datapoints'
    }
  ];

  const currentState = store.getState();
  const filteredState = {};

  whitelistedStateKeys.forEach(item => {
    const { key, defaultState, whitelistedKeys } = item;
    const keyState = {};
    if (whitelistedKeys && defaultState) {
      whitelistedKeys.forEach(prop => {
        keyState[prop] = currentState[key][prop];
      });
      filteredState[key] = {
        ...defaultState,
        ...keyState
      };
    } else {
      filteredState[key] = currentState[key];
    }
  });

  saveState(filteredState);
}
