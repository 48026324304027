import clone from 'clone';

const defaultState = [];

const datapoints = (state = defaultState, { data, type }) => {
  const workingState = clone(state);
  const workingData = clone(data);
  switch (type) {
    case 'ADD_DATAPOINT':
      return [...workingState, workingData];
    case 'ADD_DATAPOINTS':
      return [...workingState, ...workingData];
    case 'UPDATE_DATAPOINTS':
      return [...workingData];
    default:
      return state;
  }
};

export default datapoints;
